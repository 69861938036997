import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { PlanListWidgetRole } from '../../constants/elements';
import { openElementsPanel } from '../../utils/open-elements-panel';
import { openPlanListSettingsPanel } from './panels/Settings/open-plan-list-settings-panel';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  builder.set({ displayName: 'Plan List' });

  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanListSettingsPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: flowAPI.translations.t('blocks.plan-list-settings.title'),
    })
    .set('add', {
      onClick: (e) => openPlanListElementsPanel({ editorSDK, flowAPI, widgetRef: e.detail.componentRef }),
    });

  builder.behavior().set({ resizable: true, duplicatable: true });

  builder.configureConnectedComponents(PlanListWidgetRole.PlansInfoState, (containerBuilder) => {
    containerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.PlanListStates, (statesBuilder) => {
    statesBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Header, (headerBuilder) => {
    headerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.PlanList, (planListBuilder) => {
    planListBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true }, preventHide: true });
  });
};

function openPlanListElementsPanel(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, widgetRef, flowAPI } = params;
  const t = flowAPI.translations.t;
  return openElementsPanel({
    editorSDK,
    widgetRef,
    data: {
      categories: [
        {
          id: 'plan-info',
          title: t('blocks.plan-list-elements-panel.plan-info'),
        },
      ],
      elements: [
        {
          label: t('blocks.plan-list-elements-panel.title'),
          identifier: { role: PlanListWidgetRole.Title },
          categoryId: 'plan-info',
          index: 0,
        },
        {
          label: t('blocks.plan-list-elements-panel.subtitle'),
          identifier: { role: PlanListWidgetRole.Subtitle },
          categoryId: 'plan-info',
          index: 1,
        },
        {
          label: t('blocks.plan-list-elements-panel.plan-list'),
          identifier: { role: PlanListWidgetRole.PlanList },
          categoryId: 'plan-info',
          index: 2,
        },
        {
          label: t('blocks.plan-list-elements-panel.disclaimer'),
          identifier: { role: PlanListWidgetRole.Disclaimer },
          categoryId: 'plan-info',
          index: 3,
        },
      ],
    },
  });
}
